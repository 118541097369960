import AbstractApiModule from '../../AbstractApiModule';
import {
  getConvertDataMaterials,
  getConvertMaterial,
  getConvertVideos,
  getConvertUsefulMaterials,
  getConvertEvents,
  getConvertNews,
  getConvertProducts
} from '../../../utils/hcp';
import { hasProperty } from '../../../utils/common';
import { getAuthConfig } from '../../../utils/getAuthConfig';

const urlFactory = {
  getMaterials    : '/v2/internal/specialist/materials/',
  setViewMaterial : '/v1/specialist/setView/',

  getListMaterial   : (type) => `/v2/internal/specialist/materials/${type}/`,
  setReviewMaterial : (type, id) => `/v2/internal/specialist/materials/${type}/detail/${id}/reviews/`,
  registerEvent     : (id) => `/v2/internal/specialist/materials/events/detail/${id}/register/`,

  getMaterialInfo   : '/v1/specialist/getMaterialInfo/',
  saveTestingResult : '/v1/specialist/saveTestingResult/',

  getPlaylistForDetailVideo: (id) => `/v2/internal/specialist/materials/videos/detail/${id}/playlist/`,

  detectMovieHeroMaster: '/v2/internal/specialist/movie_hero_master/result/'
};

export default class HCPModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getMaterials() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getMaterials).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataMaterials(data.data);
          resolve(result);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setViewMaterial(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setViewMaterial, { materialId: id }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getArticles(sort) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('articles'), { params: { sort } }).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'articles')) {
            const userData = hasProperty(response, 'user_data') && response.user_data && hasProperty(response.user_data, 'articles')
              ? response.user_data.articles
              : null;
            const articles = getConvertMaterial(response.materials.articles, userData);

            resolve(articles);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setReviewMaterial(type, id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setReviewMaterial(type, id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getMaterialInfo(materialId) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getMaterialInfo, { params: { materialId } }).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  saveTestingResult(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.saveTestingResult, params).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getEvents() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('events')).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'events')) {
            const userData = hasProperty(response, 'user_data') && response.user_data && hasProperty(response.user_data, 'events')
              ? response.user_data.events
              : null;
            const articles = getConvertEvents(response.materials.events, userData);

            resolve(articles);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  registerEvent(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.registerEvent(id), {}, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getVideos(sort) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('videos'), { params: { sort } }).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'videos')) {
            const videos = getConvertVideos(response.materials.videos);

            resolve(videos);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getUsefulMaterials() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('useful_materials')).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'useful_materials')) {
            const usefulMaterials = getConvertUsefulMaterials(response.materials.useful_materials);

            resolve(usefulMaterials);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getOnlineTrainings(sort) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('online_trainings'), { params: { sort } }).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'trainings')) {
            const userData = hasProperty(response, 'user_data') && response.user_data && hasProperty(response.user_data, 'trainings')
              ? response.user_data.trainings
              : null;
            const trainings = getConvertMaterial(response.materials.trainings, userData);

            resolve(trainings);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getProducts() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('products')).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'products')) {
            const products = getConvertProducts(response.materials.products);

            resolve(products);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getNews() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListMaterial('news')).then(({ data }) => {
        if (data && data.data) {
          const response = data && data.data ? data.data : null;

          if (response.materials && hasProperty(response.materials, 'news')) {
            const news = getConvertNews(response.materials.news);

            resolve(news);
          }
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getPlaylistForDetailVideo(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPlaylistForDetailVideo(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  detectMovieHeroMaster(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.detectMovieHeroMaster, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }
}
